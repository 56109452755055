import React from "react";
import "./App.css";

function App() {
    return (
        <div className="container">
            <div className="content">
                <h1>Klaire</h1>
                <p className="tagline">L'intelligence artificielle pour le notariat</p>
                <a 
                    href="mailto:contact@klaire.cc" 
                    className="contact-button"
                >
                    Contactez-nous
                </a>
                <div className="company-info">
                    <h2>Informations légales</h2>
                    <p>KLAIRE, Société par actions simplifiée</p>
                    <p>Siège social : 7 RUE NOTRE-DAME DE BONNE NOUVELLE 75002 PARIS</p>
                    <p>Contact : contact@klaire.cc</p>
                </div>
            </div>
        </div>
    );
}

export default App;
